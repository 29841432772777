/* You can add global styles to this file, and also import other style files */
// / Import Extensions
// @import '~bulma/bulma.sass';

@import '~bulma/sass/utilities/initial-variables.sass';
$info: #09c !default;

@import '~bulma/sass/utilities/functions.sass';
@import '~bulma/sass/utilities/derived-variables.sass';
@import '~bulma/sass/utilities/animations.sass';
@import '~bulma/sass/utilities/mixins.sass';
@import '~bulma/sass/utilities/controls.sass';

@import '~bulma/sass/base/_all';
@import '~bulma/sass/elements/_all';
@import '~bulma/sass/form/_all';
// @import '~bulma/sass/components/_all';
@import '~bulma/sass/grid/_all';
@import '~bulma/sass/layout/_all';

// @import "~bulma-extensions/bulma-accordion/src/sass/index.sass";
// @import "~bulma-extensions/bulma-badge/src/sass/index.sass";
// @import "~bulma-extensions/bulma-calendar/src/sass/index.sass";
// @import "~bulma-extensions/bulma-carousel/src/sass/index.sass";
// @import "~bulma-extensions/bulma-checkradio/src/sass/index.sass";
@import '~bulma-extensions/bulma-divider/src/sass/index.sass';
// @import "~bulma-extensions/bulma-iconpicker/src/sass/index.sass";
// @import "~bulma-extensions/bulma-pageloader/src/sass/index.sass";
// @import "~bulma-extensions/bulma-pricingtable/src/sass/index.sass";
// @import "~bulma-extensions/bulma-quickview/src/sass/index.sass";
// @import "~bulma-extensions/bulma-ribbon/src/sass/index.sass";
// @import "~bulma-extensions/bulma-slider/src/sass/index.sass";
// @import "~bulma-extensions/bulma-steps/src/sass/index.sass";
// @import "~bulma-extensions/bulma-switch/src/sass/index.sass";
// @import "~bulma-extensions/bulma-tagsinput/src/sass/index.sass";
// @import "~bulma-extensions/bulma-timeline/src/sass/index.sass";
// @import '~bulma-extensions/bulma-tooltip/src/sass/index.sass';
@import '@creativebulma/bulma-tooltip';

.field:not(:last-child) {
  margin-bottom: 1.5rem;
}

.is-divider[data-content]::after,
.is-divider-vertical[data-content]::after {
  color: #7a7a7a;
  font-size: 17px;
}
